<template>
  <vs-row class="full-height" :style="{ margin: '0px' }" vs-justify="center">
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
      class="pt-3"
    >
      <div class="row">
        <vs-col
          cols="12"
          v-if="listconfiguration.editroute != undefined"
          class="pageHeader-container"
        >
          <div class="pageTitle-container">
            <div class="pageTitle">{{ widgetName }}</div>
            <ul class="pageBreadcrumbs">
              <li><a href="/">Dashboard </a></li>
              <li class="active">
                <a href="/">&nbsp;/ {{ widgetName }}</a>
              </li>
            </ul>
          </div>
          <div>
            <vs-button
              size="large"
              color="primary"
              type="filled"
              icon="add"
              :disabled="filteringData"
              v-bind:href="listconfiguration.editroute"
            >
              Create New
            </vs-button>
            <vs-button
              :disabled="filteringData"
              class="mr-2"
              size="large"
              color="transparent"
              type="filled"
              icon="refresh"
              @click="mount"
            >
              Refresh
            </vs-button>
          </div>
        </vs-col>
        <vs-divider color="#C1C6E5" />
        <div class="col-12">
          <div v-if="filteringData">
            <h3>Loading Data ...</h3>
            <!-- <vs-progress indeterminate color="dark">primary</vs-progress> -->
          </div>
          <div v-else>
            <vs-table
              :id="grid.id"
              :data="filteredData"
              :sst="true"
              @search="handleSearch"
              @change-page="handleChangePage"
              @sort="handleSort"
              :total="totalItems"
              :max-items="100"
              search
              stripe
              noDataText="No Available Data, click to Create new ones in the top right corner."
              class="text-nowrap"
            >
              <template slot="header">
                <td
                  v-for="(field, fieldIndex) in listconfiguration['filter']"
                  :key="fieldIndex"
                  class="mb-4 table-dropdown"
                >
                  <cassielFieldsRender
                    :formConfiguration="listconfiguration['filter']"
                    :index="fieldIndex"
                    :field="field"
                    :widgetName="widgetName"
                    :entityName="entityName"
                    @valueChanged="updateValue"
                  />
                </td>
              </template>
              <template slot="thead">
                <vs-th
                  class="text-left"
                  v-for="field in listconfiguration['datatablecolumns']"
                  :key="field.id"
                  v-bind:sort-key="
                    field.label != 'checkbox' ? field.name : null
                  "
                >
                  <input
                    v-if="field.label == 'checkbox'"
                    type="checkbox"
                    id="bulkCheckbox"
                    @change="selectAll($event)"
                  />
                  <div v-else>
                    {{ field.label }} <i class="mdi mdi-sort"></i>
                  </div>
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td
                    v-for="(field, indextd) in data[indextr]"
                    :key="indextd"
                    class="text-left"
                    :style="{
                      width:
                        indextd === data[indextr].length - 1 ? '15%' : 'auto',
                    }"
                  >
                    <span
                      :style="{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row-reverse',
                      }"
                    >
                      <input
                        type="checkbox"
                        v-if="listconfiguration['bulkactions'] && indextd === 0"
                        v-bind:value="field"
                        class="bulkCheckbox"
                        style="margin-right: 12px"
                      />
                      <span
                        v-if="
                          indextd !== data[indextr].length - 1 ||
                          listconfiguration.actions === undefined
                        "
                        v-html="field"
                      />
                    </span>

                    <div
                      v-if="
                        indextd === data[indextr].length - 1 &&
                        listconfiguration.actions !== undefined
                      "
                      class="text-right align-right"
                    >
                      <vs-button
                        size="small"
                        v-if="listconfiguration.actions.preview"
                        v-bind:href="previewLink + '' + field"
                        target="_blank"
                        v-tooltip.bottom="{
                          content: 'Preview',
                          trigger: 'hover',
                        }"
                        icon="visibility"
                        type="filled"
                        color="warning"
                        class="ml-1 button-preview"
                      />

                      <vs-button
                        size="small"
                        icon="mode_edit"
                        type="filled"
                        color="primary"
                        class="ml-1"
                        v-if="listconfiguration.actions.edit"
                        v-bind:href="editLink + '?cassielId=' + field"
                        target="_blank"
                        v-tooltip.bottom="{ content: 'Edit', trigger: 'hover' }"
                      />

                      <vs-button
                        size="small"
                        icon="insights"
                        type="filled"
                        color="warning"
                        class="ml-1"
                        v-if="listconfiguration.actions.insights"
                        v-bind:href="insightsLink + '?cassielId=' + field"
                        target="_blank"
                        v-tooltip.bottom="{
                          content: 'Insights',
                          trigger: 'hover',
                        }"
                      />

                      <vs-button
                        v-if="listconfiguration.actions.btn"
                        v-bind:href="
                          listconfiguration.actions.btn.url +
                          '?' +
                          listconfiguration.actions.btn.urlValue +
                          '=' +
                          field
                        "
                        v-tooltip.bottom="{
                          content: listconfiguration.actions.btn.label,
                          trigger: 'hover',
                        }"
                        type="filled"
                        color="primary"
                        class="ml-1"
                        size="small"
                        :style="{ padding: '4px 15px' }"
                      >
                        <span :style="{ 'font-size': '11px !important' }">
                          {{ listconfiguration.actions.btn.label }}
                        </span>
                      </vs-button>
                      
                      <vs-button
                        size="small"
                        v-if="listconfiguration.actions.details"
                        v-bind:href="detailsLink + '?cassielId=' + field"
                        target="_blank"
                        v-tooltip.bottom="{
                          content: 'Details',
                          trigger: 'hover',
                        }"
                        icon="insights"
                        type="filled"
                        color="warning"
                        class="ml-1"
                      />

                      <vs-button
                        size="small"
                        v-if="listconfiguration.actions.copy"
                        href="#"
                        @click="copy(field)"
                        v-tooltip.bottom="{ content: 'Copy', trigger: 'hover' }"
                        icon="content_copy"
                        type="filled"
                        color="primary"
                        class="ml-1"
                      />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- <vs-divider /> -->
            <footer class="vs-table--footer">
              <vs-row v-if="listconfiguration['bulkactions']">
                <div
                  class="bulkaction-container"
                  style="display: flex; align-items: center"
                >
                  <input
                    type="checkbox"
                    value="1"
                    class="bulkCheckbox"
                    style="margin-right: 12px"
                    @change="handleSelectAll"
                  />
                  <cassielFieldsRender
                    :index="0"
                    :formConfiguration="bulkActionArray"
                    :field="bulkActionArray"
                    :widgetName="widgetName"
                    :entityName="entityName"
                    @valueChanged="handleBulkChange"
                  />
                </div>

                <vs-col vs-w="6" v-if="bulkactionchoice">
                  <template
                    v-for="(value, key) in listconfiguration[
                      'bulkactionsfields'
                    ]"
                  >
                    <vs-col
                      vs-w="6"
                      v-for="(field, fieldIndex) in value"
                      :key="fieldIndex"
                      :id="key"
                    >
                      <cassielFieldsRender
                        v-model="field.value"
                        :index="fieldIndex"
                        :updatevalue="setBulkActionParams"
                        :widgetName="widgetName"
                        :entityName="entityName"
                        :field="field"
                        :form-configuration="
                          listconfiguration['bulkactionsfields'][key]
                        "
                      />
                    </vs-col>
                  </template>
                </vs-col>
                <vs-col vs-w="3" vs-justify="flex-end" vs-align="flex-end">
                  <vs-button
                    size="large"
                    type="filled"
                    color="primary"
                    class="ml-1"
                    style="width: 163px"
                    @click="bulkaction"
                  >
                    Apply
                  </vs-button>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col
                  class="mt-3"
                  vs-w="12"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <vs-pagination
                    color="primary"
                    :total="lastPage"
                    v-model="currentPage"
                    @change="handleChangePage()"
                    :max="5"
                    goto
                  />
                </vs-col>
              </vs-row>
            </footer>
          </div>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>
<style>
th {
  color: white;
  padding: 6px 0 !important;
}

.vs-table--tbody-table .vs-table--thead tr {
  background: #586bb2 !important;
}

.table-dropdown {
  width: 247px;
}

p {
  margin-bottom: 0 !important;
}

.label {
  color: #fff;
  border: none;
  cursor: default;
  display: inline-flex;
  outline: 0;
  padding: 5px 10px;
  /*font-size: .8125rem;*/
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  border-radius: 0.25em;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  font-weight: 400;
  min-width: 91px;
}

.label-info {
  background-color: #e41c42;
}

.label-success {
  background-color: #39b550;
}

.label-warning {
  background-color: #ffb300;
}

.vs-table--tbody-table .tr-values.selected {
  cursor: auto !important;
}

th.text-left.w-30:last-child i {
  display: none;
}

.form-control {
  border: 1px solid #586bb2 !important;
}
.includeIconOnly.small {
  width: 42px !important;
  height: 42px !important;
}
button.vs-button-filled.button-preview {
  background: #c1c6e5 !important;
  color: #586bb2;
}
.vs-table--footer {
  background: #fbfbfb;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  padding: 15px;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 1px solid #586bb2;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #495057;
}

.con-input-search.vs-table--search input {
  max-width: 100%;
}

header .form-group,
footer .form-group {
  margin-bottom: inherit;
  /* width: 150px; */
  height: 42px;
}

header .form-group label,
footer .form-group label {
  display: none;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  left: 0;
}

footer > div {
  margin-left: 15px;
  margin-bottom: 10px;
}
.bulkaction-container {
  margin-right: -10px;
  margin-bottom: 10px;
}
.bulkaction-container .con-select.form-control {
  margin-right: -20px;
}
</style>
<script>
import cassielFieldsRender from "@/views/edit/Component/cassielFieldsRender";

export default {
  name: "list",
  mixins: [],
  props: {
    widgetName: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      default: "prop2",
    },
  },

  watch: {
    widgetName: function () {
      // call the method which loads your initial state
      this.mount();
    },
    entityName: function () {
      // call the method which loads your initial state
      this.mount();
    },

    listconfiguration: {
      deep: true,
      handler(newV) {
        if (newV.filter) {
          newV.filter[1].disabled = !newV.filter[0].value;
        }
        if (
          newV.bulkactionsfields &&
          newV.bulkactionsfields["change-section"]
        ) {
          newV.bulkactionsfields["change-section"][1].disabled = false;
          if (
            !newV.bulkactionsfields["change-section"][0].value ||
            newV.bulkactionsfields["change-section"][0].value.length === 0
          ) {
            newV.bulkactionsfields["change-section"][1].disabled = true;
          }
        }
      },
    },
  },
  data: () => ({
    grid: {
      id: "datatable",
      search: true,
    },
    filteringData: true,
    listconfiguration: [],
    filteredData: [],
    totalItems: 0,
    currentPage: 1,
    lastPage: 1,
    perPage: 100,
    query: "",
    Orderkey: "id",
    OrderDir: "DESC",
    previewLink: "",
    editLink: "",
    insightsLink: "",
    bulkactionchoice: "",
    bulkactionparams: {},
    bulkActionArray: {
      type: "dropdown",
      label: "Bulk Action",
      multiple: false,
      name: "bulk action",
      placeholder: "Bulk Action",
      value: [],
      options: [
        {
          id: "",
          section: "Bulk Action",
          text: "Bulk Action",
        },
      ],
    },
    filter: {},
  }),

  mounted: function () {
    this.mount();
    this.$vs.loading({
      type: "corners",
    });
  },

  components: {
    cassielFieldsRender,
  },

  methods: {
    selectAll(event) {
      let checkboxes = document.getElementsByClassName("bulkCheckbox");
      for (let checkbox of checkboxes) {
        checkbox.checked = event.target.checked;
      }
    },

    bulkaction() {
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      let checkedValue = [];
      let inputElements = document.getElementsByClassName("bulkCheckbox");
      for (let i = 0; inputElements[i]; ++i) {
        if (inputElements[i].checked) {
          checkedValue.push(inputElements[i].value);
        }
      }

      let formData = new FormData();
      formData.append("ids", JSON.stringify(checkedValue));
      let selectedBulkAction = this.bulkactionchoice
        .toLowerCase()
        .replace(/\s/g, "-");

      if (this.listconfiguration["bulkactionsfields"]) {
        let params = { "article-subsection": "" };
        this.listconfiguration["bulkactionsfields"][selectedBulkAction].forEach(
          (oneField) => {
            if (oneField.name === "article-subsection") {
              params["article-subsection"] = oneField.value;
            }
          }
        );
        formData.append("params", JSON.stringify(params));
      }

      this.$vs.loading({
        type: "corners",
      });
      this.$http({
        url: this.listconfiguration["bulkactionsapis"][selectedBulkAction],
        method: "POST",
        data: formData,
        headers: headers,
      })
        .then((response) => {
          this.$vs.loading.close();
          if (!response.error) {
            this.$swal({
              title: "success!",
              html:
                this.listconfiguration["bulkactionsresponsemessage"][
                  selectedBulkAction
                ] +
                "<p>" +
                document.getElementsByName("article-subsection")[1].value +
                "</p>",
              confirmButtonText: "close",
            });
            this.mount();
          } else {
            this.$swal({
              title: "success!",
              html:
                this.listconfiguration["bulkactionsresponsemessage"][
                  selectedBulkAction
                ] +
                "<p>" +
                document.getElementsByName("article-subsection")[1].value +
                "</p>",
              confirmButtonText: "close",
            });
            this.getTableData();
          }
        })
        .catch(() => {
          this.$swal({
            title: "Error!",
            html: "No article/s were selected. Please pick an article to apply the section change.",
            confirmButtonText: "close",
            customClass: {
              title: "error",
            },
          });
          this.$vs.loading.close();
        });
    },

    copy(id) {
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.$vs.loading({
        type: "corners",
      });
      this.$http({
        url:
          "/copy" +
          "/" +
          this.widgetName +
          "/" +
          this.entityName +
          "?cassielId=" +
          id,
        method: "GET",
        headers: headers,
      })
        .then((response) => {
          this.$vs.loading.close();
          if (!response.error) this.mount();
          else console.log(response.message);
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    mount() {
      this.$vs.loading({
        type: "corners",
      });
      this.previewLink = process.env.VUE_APP_PREVIEW_BASE_URL;

      this.detailsLink =
        "/details" + "/" + this.widgetName + "/" + this.entityName;
      this.editLink = "/edit" + "/" + this.widgetName + "/" + this.entityName;
      this.insightsLink = "/insights/" + this.widgetName + "/" + this.entityName;

      const baseListConfigurationUrl = this.$apiList.baseListConfigurationApi;

      let listConfigurationUrl =
        baseListConfigurationUrl +
        "/" +
        this.widgetName +
        "/" +
        this.entityName;

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.filteringData = true;
      this.$http({
        url: listConfigurationUrl,
        method: "GET",
        headers: headers,
      }).then((response) => {
        if(!response.data.datatablecolumns)  window.location.href ="/";
        if (
          response.data["bulkactions"] !== undefined &&
          this.bulkActionArray.options.length === 1
        ) {
          response.data["bulkactions"].map((item) => {
            this.bulkActionArray.options.push({
              id: item,
              section: item,
              text: item,
            });
          });
        }

        this.listconfiguration = response.data;
        this.$vs.loading.close();
        this.Orderkey = this.listconfiguration.datatablecolumns[0].name;
        this.$nextTick(function () {
          if (Object.entries(this.$route.query).length > 0) {
            this.listconfiguration.editroute =
              this.listconfiguration.editroute + "?";
          }
          Object.entries(this.$route.query).forEach(([key, value]) => {
            this.listconfiguration.editroute =
              this.listconfiguration.editroute + key + "=" + value + "&";
          });
        });
        this.is_data_fetched = true;

        this.getTableData();
      });
    },

    handleSearch(searching) {
      if (searching.length >= 3) {
        this.query = searching;
        this.getTableData();
      }
    },

    handleChangePage() {
      // this.currentPage = page;
      this.getTableData();
    },

    handleSort(key, active) {
      if (key != null) {
        this.Orderkey = key;
        this.OrderDir = active;
        this.getTableData();
      }
    },

    getTableData(filter) {
      let formData = new FormData();

      formData.append("page", this.currentPage);
      formData.append("length", this.perPage);
      formData.append("start", (this.currentPage - 1) * this.perPage);
      formData.append("search", this.query);
      formData.append("orderKey", this.Orderkey);
      formData.append("orderDirection", this.OrderDir);
      Object.entries(this.$route.query).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (filter !== undefined) {
        formData.append("filter", JSON.stringify(filter));
      } else {
        if (Object.keys(this.filter).length !== 0)
          formData.append("filter", JSON.stringify(this.filter));
      }

      const baseListDataApi = this.$apiList.baseListDataApi;

      let listDataUrl =
        baseListDataApi + "/" + this.widgetName + "/" + this.entityName;

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      this.$http({
        url: listDataUrl,
        method: "POST",
        headers: headers,
        data: formData,
      }).then((response) => {
        this.filteringData = false;
        this.$nextTick(function () {
          this.filteredData = response.data.data;
          this.totalItems = parseInt(response.data.recordsFiltered);
          this.lastPage = parseInt(Math.ceil(this.totalItems / this.perPage));
        });
      });
    },
    handleBulkChange: function (value) {
      this.bulkactionchoice = value;
    },
    updateValue: function (value, key) {
      let query = {};
      if (value && value.trim() !== "") {
        query = { key: key, value: value.toString() };
      } else {
        query = null;
      }
      //todo: need to figure out why when this is set to true, the grid runs 2 get operations !!!!!
      // this.filteringData = true;
      this.filter = query;
      this.getTableData(query);
    },

    setBulkActionParams: function (value, index) {
      this.$emit("updatevalue", value, index);
      this.bulkactionparams[value.key] = value.value;
    },

    handleSelectAll: function (e) {
      let inputElements = document.getElementsByClassName("bulkCheckbox");
      for (let i = 0, l = inputElements.length; i < l; ++i) {
        inputElements[i].checked = e.target.checked;
      }
    },
  },
};
</script>
